import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"

import Layout from "../../../components/layout"

export default class extends React.Component {
  state = {
    nombre: '',
    celular: '',
    email: '',
    cuit: 0,
    actividad: "",
    masaSalarial: 0,
    cantidadEmpleados: 0,
    alicuotaWds: 0,
    costoMensual: 0,
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState(this.props.location.state)
    } else {
      navigate("/seguros/cotizar-art")
    }
  }

  render() {
    const { nombre, celular, email, cuit, actividad, cantidadEmpleados, masaSalarial, alicuotaWds, costoMensual } = this.state
    return (
      <Layout>

        <section className="pb-5 pt-5" id="faq">

          <h2 className="h3 block-title text-center mb-5">Resumen Contratación
            <small>Muchas gracias por confiar en nosotros. <br/><br/>
            <strong className="alert-primary">Su contratación está en proceso y pronto un asesor se contactará con usted.</strong></small>
          </h2>

          <div className="container">

            <div className="row">

              <div className="col-md-6 offset-md-3 pb-5">


                <div className="card blog-card">
                  <div className="card-body">
                    <h6 className="post-title city">Datos de contacto</h6>

                    <p className="text-muted testimonial-p">
                      <strong>Nombre:</strong> {nombre} <br/>
                      <strong>Celular:</strong> {celular} <br/>
                      <strong>Email:</strong> {email}
                    </p>

                    <h4 className="post-title city">Datos de la solicitud</h4>
                    <p className="text-muted testimonial-p">
                      <strong>CUIT:</strong> {cuit} <br/>
                      <strong>Actividad:</strong> {actividad} <br/>
                      <strong>Cantidad de empleados:</strong> {cantidadEmpleados} <br/>
                      <strong>Masa salarial:</strong> {masaSalarial} <br/>
                      <strong>Alicuota:</strong> {alicuotaWds} <br/>
                    </p>

                  </div>
                  <div className="card-footer"><a className="post-author" href="/">
                    <div className="post-author-name" style={{ fontSize: "1.2rem"}}>Costo Mensual</div>
                  </a>
                    <div className="post-meta"><span className="text-success font-weight-bold" style={{ fontSize: "2rem"}}>${costoMensual}</span></div>
                  </div>
                </div>


              </div>


            </div>

            <div className="text-center my-5">
              <Link className="btn btn-style-6 btn-primary mr-3 mb-3" to="/seguros/cotizar-art">
                Realizar una nueva cotización
              </Link>
            </div>


          </div>

        </section>

      </Layout>
    )
  }
}
